<template>
  <div>
    <div class="flex-align tab-container">
      <div class="tab-head unselect" v-for="item in menu" :key="'tab-head-'+item.id"
          :class="{'tab-head-selected': selectedItem === item.id}" @click="clickItem(item)">{{ item.name }}</div>
    </div>
    <template v-for="item in menu">
      <slot :name="'tab'+item.id" v-if="selectedItem === item.id"></slot>
    </template>
  </div>
</template>

<script>
  export default {
    name: "TabPage",
    props: {
      menu: {
        type: Array,
        required: true
      },
    },
    created() {
      let path = this.$route.path.split('/');
      path = path[path.length-1];
      let selected = this.menu.filter(i=> { return i.path === path });
      if(selected.length > 0) {
        this.selectedItem = selected[0].id;
      } else {
        this.selectedItem = this.menu[0].id;
      }
    },
    data() {
      return {
        selectedItem: 0
      }
    },
    methods: {
      clickItem(item) {
        this.selectedItem = item.id;
        this.$emit('selected', item);
      }
    },
    watch: {
      '$route'() {
        let paths = this.$route.path.split('/');
        let path = paths[paths.length-1];
        let index = this.menu.findIndex(i => { return i.path === path });
        if(index>-1 && !this.$route.query.tab) {
          this.clickItem(this.menu[index]);
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .tab-container
    background-color $gray4
  .tab-head
    width 200px
    padding 12px
    color $sub3
    font-size 16px
    font-weight 500
    text-align center
  .tab-head-selected
    color $main
    background-color white

  @media (max-width: 500px)
    .tab-head
      background-color white
      padding-bottom 13px
      font-size 14px

    .tab-head-selected
      border 0
      padding-bottom 12px

</style>
